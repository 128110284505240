import React, { useRef, useEffect, useState, createRef } from "react"
import styled, { css } from "styled-components"
import { color, font, device } from "../../layout/global"
import { Link } from "gatsby"
import Slider from "react-slick"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const SpecsTable = ({
  id,
  specsTable,
  location,
  specsLength,
  svgWidth,
  btnString,
  hasLessHeight,
  blue,
  br,
}) => {

  const triggerRef = useRef(null),
    imageRef = useRef(specsTable.map(() => createRef())),
    imageMRef = useRef(specsTable.map(() => createRef())),
    titleRef = useRef(specsTable.map(() => createRef())),
    descRef = useRef(specsTable.map(() => createRef())),
    spec1tRef = useRef(specsTable.map(() => createRef())),
    spec2tRef = useRef(specsTable.map(() => createRef())),
    spec3tRef = useRef(specsTable.map(() => createRef())),
    spec4tRef = useRef(specsTable.map(() => createRef())),
    buttonRef = useRef(specsTable.map(() => createRef())),
    tableRef = useRef(null)

  const [currentSlide, setCurrentSlide] = useState(0)

  let slidesToShow = specsLength

  if (
    typeof window !== "undefined" &&
    window.matchMedia(device.tabletP).matches
  ) {
    specsLength >= 3
      ? (slidesToShow = 3)
      : specsLength === 2
      ? (slidesToShow = 2)
      : specsLength === 1 && (slidesToShow = 1)
  } else if (
    typeof window !== "undefined" &&
    window.matchMedia(device.mobileP).matches
  ) {
    slidesToShow = 1
  }

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.matchMedia(device.tabletP).matches
    ) {
      let tl = gsap.timeline({
        paused: true,
        scrollTrigger: {
          anticipatePin: 1,
          trigger: triggerRef.current,
          toggleActions: "play none none none",
          start: "center center",
          pinSpacing: true,
        },
      })
      tl.to(tableRef.current, { opacity: 1, duration: 1 })
      tl.to({}, { duration: 1 })
    }
  }, [])

  useEffect(() => {
    let numSlides = document.querySelectorAll(`#${id} .slick-slide`).length
    let slideToCheck = document.querySelectorAll(
      `#${id} .slick-slide.slick-active`
    )
    let slideDataIndex = []
    slideToCheck.forEach(el => slideDataIndex.push(el.dataset.index))
    let firstSlide = "0"
    let lastSlide = (numSlides - 1).toString()
    let prevArrow = document.querySelector(`#${id} .slick-prev`)
    let nextArrow = document.querySelector(`#${id} .slick-next`)

    if (
      slideDataIndex.includes(firstSlide) &&
      prevArrow !== null &&
      nextArrow !== null
    ) {
      prevArrow.style.opacity = 0
      prevArrow.style.pointerEvents = "none"
    } else if (
      slideDataIndex.includes(lastSlide) &&
      prevArrow !== null &&
      nextArrow !== null
    ) {
      nextArrow.style.opacity = 0
      nextArrow.style.pointerEvents = "none"
    } else if (prevArrow !== null && nextArrow !== null) {
      prevArrow.style.opacity = 1
      prevArrow.style.pointerEvents = "all"
      nextArrow.style.opacity = 1
      nextArrow.style.pointerEvents = "all"
    }
  }, [currentSlide, id])

  const Arrows = props => {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          position: "absolute",
          top: "50%",
          zIndex: "2",
          width: "10px",
          height: "20px",
          cursor: "pointer",
          fontSize: "15px",
        }}
        onClick={onClick}
        role="presentation"
      />
    )
  }

  let sliderSettings = {
    slidesToShow: slidesToShow,
    infinite: false,
    swipeToSlide: true,
    lazyLoad: true,
    nextArrow: <Arrows role="button" />,
    prevArrow: <Arrows role="button" />,
    afterChange: current => setCurrentSlide(current),
    // centerMode: typeof window !== 'undefined' && window.matchMedia(device.tabletP).matches ? false : true,
  }

  const breakpoints = useBreakpoint()

  return (
    <Wrapper
      id={id}
      className="container"
      slidesToShow={slidesToShow}
      length={specsLength}
      ref={triggerRef}
      svgWidth={svgWidth}
      hasLessHeight={hasLessHeight}
      blue={blue}
    >
      <div className="specs-table-container" ref={tableRef}>
        <Slider {...sliderSettings}>
          {specsTable.map((item, index) => (
            <div key={item.title + index} className="specs-table">
              {!breakpoints.tl ? (
                <div className="specs-image" ref={imageRef.current[index]}>
                  <img src={item.image} alt='microram' />
                </div>
              ) : (
                <div className="specs-imageM" ref={imageMRef.current[index]}>
                  <img src={item.imageM} alt='microram' />
                </div>
              )}
              <h4
                ref={titleRef.current[index]}
                dangerouslySetInnerHTML={{ __html: item.title }}
              ></h4>
              <h5
                ref={descRef.current[index]}
                dangerouslySetInnerHTML={{ __html: item.description }}
              ></h5>
              <div className="specs-subtable">
                {item.spec1title && (
                  <h6 ref={spec1tRef.current[index]}>{item.spec1title}</h6>
                )}
                {item.spec2title && (
                  <h6 ref={spec2tRef.current[index]}>{item.spec2title}</h6>
                )}
                {item.spec3title && (
                  <h6 ref={spec3tRef.current[index]}>{item.spec3title}</h6>
                )}
                {item.spec4title && (
                  <h6 ref={spec4tRef.current[index]}>{item.spec4title}</h6>
                )}
              </div>
              {!breakpoints.tl && btnString && (
                <div className="specs-button-container">
                  {location && !br ? (
                    <Link
                      to={`${location.pathname}#form-contact`}
                      alt={btnString}
                      state={{ product: item.title }}
                    >
                      <div className="button" ref={buttonRef.current[index]}>
                        <span className="contacto">
                          {btnString} <span className="mais">+</span>
                        </span>
                        <svg>
                          <polyline
                            className="o2"
                            points="0 0, 113 0, 113 28, 0 28, 0 0"
                          ></polyline>
                        </svg>
                      </div>
                    </Link>
                  ) : (
                    <a
                      href="https://ram.massamadreblog.com.br/quero-comprar"
                      target="_blank"
                      rel="noreferrer noopener"
                      alt={btnString}
                    >
                      <div className="button">
                        <span className="contacto">
                          {btnString} <span className="mais">+</span>
                        </span>
                        <svg>
                          <polyline
                            className="o2"
                            points="0 0, 113 0, 113 28, 0 28, 0 0"
                          ></polyline>
                        </svg>
                      </div>
                    </a>
                  )}
                </div>
              )}
            </div>
          ))}
        </Slider>
        {breakpoints.tl && btnString && (
          <div className="specs-button-container">
            {location && !br ? (
              <Link to={`${location.pathname}#form-contact`} alt={btnString}>
                <div className="button">
                  <span className="contacto">
                    {btnString} <span className="mais">+</span>
                  </span>
                  <svg>
                    <polyline
                      className="o2"
                      points="0 0, 113 0, 113 28, 0 28, 0 0"
                    ></polyline>
                  </svg>
                </div>
              </Link>
            ) : (
              <a
                href="https://ram.massamadreblog.com.br/quero-comprar"
                target="_blank"
                rel="noreferrer noopener"
                alt={btnString}
              >
                <div className="button">
                  <span className="contacto">
                    {btnString} <span className="mais">+</span>
                  </span>
                  <svg>
                    <polyline
                      className="o2"
                      points="0 0, 113 0, 113 28, 0 28, 0 0"
                    ></polyline>
                  </svg>
                </div>
              </a>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

export default SpecsTable

const Wrapper = styled.section`
  position: relative;
  background-color: black;
  width: 100%;
  opacity: 1;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1750px !important;

  @media ${device.mobileP} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding: 50px 0;
  }

  @media ${device.tabletP} {
    display: block;
    max-width: 100%;
    padding: 0;
    ${props =>
      props.hasLessHeight &&
      css`
        margin-top: -20%;
      `}
  }

  .specs-table-container {
    @media ${device.mobileP} {
      opacity: 1;
    }

    @media ${device.tabletP} {
      margin: 250px auto;
      opacity: 0;
    }
  }

  .specs-table {
    @media ${device.mobileP} {
      display: grid !important;
      grid-template-rows: 200px auto 0px auto auto;
    }

    @media ${device.tabletP} {
      display: grid !important;
      grid-template-rows: 200px repeat(4, auto);
      height: 100%;
    }

    h4 {
      ${font.robotoMedium};
      color: ${color.white};
      text-transform: uppercase;

      @media ${device.mobileP} {
        text-align: center;
        font-size: 1.4rem;
        margin-top: 10px;
      }

      @media ${device.tabletP} {
        text-align: left;
        font-size: 1.8rem;
        margin-top: 25px;
      }
    }

    h5 {
      margin-top: 5px;
      ${font.robotoMedium};
      color: ${color.greyDarker};
      font-size: 1.5rem;
      line-height: normal;

      @media ${device.mobileP} {
        display: none;
      }

      @media ${device.tabletP} {
        display: none;
      }
    }

    h6 {
      position: relative;
      padding-bottom: 5px;
      ${font.robotoMedium};
      color: #97999b;
      text-transform: uppercase;
      line-height: normal;

      @media ${device.mobileP} {
        text-align: center;
        font-size: 1.1rem;
        margin-top: 5px;
      }

      @media ${device.tabletP} {
        font-size: 1.3rem;
        margin-top: 20px;
        text-align: left;
      }
    }

    h6:after {
      content: "";
      position: absolute;
      background-color: ${color.greyDark};
      width: 75px;
      height: 3px;
      top: 100%;

      @media ${device.mobileP} {
        display: none;
      }

      @media ${device.tabletP} {
        display: block;
      }
    }

    p {
      margin-top: 20px;
      padding-bottom: 5px;
      ${font.robotoMedium};
      color: ${color.greyDark};
      text-transform: uppercase;
      line-height: normal;

      @media ${device.mobileP} {
        text-align: right;
        font-size: 1.1rem;
      }

      @media ${device.tabletP} {
        text-align: left;
        font-size: 1.3rem;
      }
    }
  }

  .specs-subtable {
    @media ${device.mobileP} {
      margin-top: 20px;
    }

    @media ${device.tabletP} {
      margin-top: 10px;
    }
  }

  .specs-image,
  .specs-imageM {
    display: flex;
    align-items: flex-end;

    @media ${device.mobileP} {
      justify-content: center;
    }

    @media ${device.tabletP} {
      justify-content: flex-start;
    }

    svg {
      height: auto;

      @media ${device.mobileP} {
        width: 80px;
      }

      @media ${device.tabletP} {
        width: 120px;
      }
    }

    img {
      width: ${props => (props.svgWidth ? props.svgWidth : "unset")};
    }
  }

  .specs-button-container {
    display: flex;
    align-items: flex-end;

    @media ${device.mobileP} {
      justify-content: center;
      margin-top: 150px;
    }
    @media ${device.tabletP} {
      justify-content: flex-end;
      margin-top: 50px;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      ${font.robotoMedium};
      color: ${color.white};
      font-size: 1.1rem;
      background-color: transparent;
      padding: 7px 12px 7px 12px;
      text-transform: uppercase;

      .contacto {
        position: absolute;
      }

      .mais {
        padding-left: 8px;
      }

      cursor: pointer;

      svg {
        height: 28px;
        width: 113px;
        fill: none;
        stroke-width: 5px;

        .o2 {
          color: ${props => (props.blue ? "#07c7ed" : color.red)};
          stroke-dasharray: 40 330;
          stroke-dashoffset: 250;
          transition: all 0.6s ease-in-out;
        }
      }

      &:hover {
        .o2 {
          stroke-dashoffset: -120;
        }

        a {
          color: ${props => (props.blue ? "#07c7ed" : color.red)};
        }
      }
    }
  }

  .slick-track {
    display: flex;
    flex-direction: row;

    @media ${device.mobileP} {
      width: 100%;
    }

    @media ${device.tabletP} {
      ${props =>
        props.slidesToShow === 1
          ? "width: 33% !important"
          : props.slidesToShow === 2 && "width: 66% !important"}
    }
  }

  .slick-slide {
    @media ${device.mobileP} {
      padding: 0 60px;
    }
    @media ${device.tabletP} {
      padding: 0 100px;
    }

    & div {
      height: 100%;
    }
  }

  .slick-slide div:focus {
    outline: none !important;
  }

  .slick-next {
    right: 30px;
    z-index: 1 !important;
    transition: all 0.3s ease-out;

    &:before {
      content: "\\276F";
      z-index: 3;
      width: fit-content;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover:before {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }

  .slick-prev {
    left: 30px;
    z-index: 1 !important;
    transition: all 0.3s ease-out;

    &:before {
      content: "\\276E";
      z-index: 3;
      width: fit-content;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:hover:before {
      color: ${props => (props.blue ? "#07c7ed" : color.red)};
    }
  }
`
