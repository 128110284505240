import React from "react"
import styled from "styled-components"
import { font, ease } from "../../layout/global"
import CTAScroll from "../CTA-Scroll/landing"

// COMPONENT
const Video = React.forwardRef(
  ({ src, className, id, image, toClick, info }, ref) => {
    return (
      <Wrapper
        ref={ref}
        id={id}
        className={className}
        imgMobile={image}
        isVisible={true}
      >
        <figure className="container">
            <img 
                src={image} 
                alt='video-mobile' 
                style={{
                    objectFit:'cover', 
                    objectPosition:'center center'
                }}
            />
          <div className="info-wrapper">
            <div className="text-container">
              <div
                className="overtitle"
                dangerouslySetInnerHTML={{ __html: info.overtitle }}
              ></div>
              <div
                className="title"
                dangerouslySetInnerHTML={{ __html: info.title }}
              ></div>
            </div>
            <CTAScroll className="cta" text="scroll" toClick={toClick} />
          </div>
        </figure>
      </Wrapper>
    )
  }
)

export default Video

const Wrapper = styled.div`
  .container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
  }

  .image-mobile {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .info-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 2.5%;
  }

  .text-container {
    width: 100%;
    opacity: ${props => (props.isVisible ? "1" : "0")};
    pointer-events: ${props => (props.isVisible ? "all" : "none")};
    transition: all 0.3s ${ease.out};
  }

  .overtitle {
    ${props => (props.ko ? font.Noto700 : font.robotoBold)};
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
  }

  .title {
    ${props => (props.ko ? font.Noto400 : font.financierLight)};
    color: white;
    text-align: center;
    font-size: 4rem;
    line-height: 1;
  }

  .cta {
    opacity: 1;

    & .scroll-container {
      pointer-events: all;
    }
  }
`
